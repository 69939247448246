import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
const Form = () => {
  const [paramsAdded, setParamsAdded] = useState(false);
  const [paramsString, setParamsString] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const handleDecrypt = (encryptedToken:string) => {
    console.log(encryptedToken)
    const bytes = CryptoJS.AES.decrypt(atob(encryptedToken), 'M!r@Cl$@5o7');
    console.log(bytes)
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }
  useEffect(() => {
    const fetchParams = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const encryptedToken = urlParams.get("token");
        const token = handleDecrypt(encryptedToken||"")
        urlParams.delete("token")
        const newUrl = `${location.pathname}?${urlParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
        const submissionID = urlParams.get("submissionID");

        fetch(`/api/getPreviousSubmission`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ submissionID }),
        })
          .then((response) => response.json())
          .then(async (data) => {
            const queryObj = data.data.queryObj;

            let queryStr = window.location.search;
            for (let key in queryObj) {
              queryStr += `&${key}=${queryObj[key]}`;
            }



            setParamsString(queryStr);

            setParamsAdded(true);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchParams();
  }, []);

  /*useEffect(() => {
        const fetchHtmlContent = async () => {
            try {
                fetch(`/jotform.html`).then((response) => response.text()).then((html) => {
                    setHTMLContent(html)
                })
            } catch (error) {
                console.error(error)
            }
        }

        if (paramsAdded) {
            fetchHtmlContent()
        }
    }, [paramsAdded])*/

  if (!paramsAdded) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <iframe
      src={`/jotform.html${paramsString}`}
      style={{ width: "100%", height: "100vh", border: "none" }}
    ></iframe>
  );
};

export default Form;
